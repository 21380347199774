import React, { useReducer } from 'react';
import AuthContext from './authContext';
import AuthReducer from './authReducer';
import clientAxios from '../../config/axios';
import {
  SUCCESS_LOGIN,
  FAILED_LOGIN,
  GET_USER,
  FAILED_USER,
  LOGOUT
} from './authTypes';
import tokenAuth from '../../config/tokenAuth';

const AuthState: React.FC = (props: any) => {

  const initialState = {
    token: localStorage.getItem('token'),
    auth: false,
    user: null,
    rol: null,
    msg: null,
  };

  const [state, dispatch] = useReducer(AuthReducer, initialState);


  const authentication = async (data: any) => {
    try {
      const response = await clientAxios.post('api/user/login', data);
      console.log(response);
      dispatch({
        type: SUCCESS_LOGIN,
        payload: response.data.data
      })
      getToken();
    } catch (err: any) {
      const alert = {
        msg: err.response.data.msg,
      }
      dispatch({
        type: FAILED_LOGIN,
        payload: alert
      })
    }
  };

  const getToken = async () => {
    const token = localStorage.getItem('token');
    if (token) {
      tokenAuth(token);
      const response = await clientAxios.get('user/auth');
      dispatch({
        type: GET_USER,
        payload: response.data
      })
    } else {
      dispatch({
        type: FAILED_USER
      })
    }
  };

  const logout = () => {
    dispatch({
      type: LOGOUT
    })
  };

  return (
    <AuthContext.Provider
      value={{
        token: state.token,
        auth: state.auth,
        user: state.user,
        msg: state.msg,
        rol: state.rol,
        authentication,
        getToken,
        logout
      }}
    >
      {props.children}
    </AuthContext.Provider>
  )
}

export default AuthState;
