import { useContext, useEffect, useState } from 'react';
import { Paper, Modal } from '@material-ui/core';
import css from './planes.module.css';
import Button from '@material-ui/core/Button';
import Checkbox from '../../../shared/form/checkbox';
import { required } from '../../../../helpers/forms/validators';
import TextField from '../../../shared/form/text-field';
import MaterialTable from 'material-table';
import arrayMutators from 'final-form-arrays';
import { CircularProgress } from '@material-ui/core';
import { FieldArray } from 'react-final-form-arrays'
import PlansContext, { IPlan } from '../../../../context/planes/planesContext';
import { Field, Form } from 'react-final-form';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import PopUpSuccess from '../../../shared/popup-success/popup-success';
import cx from 'classnames';
import { onlyNums } from '../../../../helpers/forms/normalize';

const columns = [
  {
    title: 'Nombre',
    field: 'name'
  },
  {
    title: 'Descripcion',
    field: 'description'
  },
  {
    title: 'Posición',
    field: 'position',
  }
];

const PlanesTable = () => {

  const plansContext = useContext(PlansContext);

  const {
    getPlans,
    plans,
    setFormStatus,
    modeForm,
    showForm,
    setSelectedPlan,
    planSelected,
    editPlan,
    deletePlan,
    msg,
    addPlan
  } = plansContext;

  const closeModal = () => {
    setFormStatus({
      status: false,
      mode: ''
    });
  };

  const handleNewPlan = () => {
    setFormStatus({
      mode: 'ADD',
      status: true
    });
    setSelectedPlan('');
  };

  useEffect(() => {
    if (plans && plans.length === 0) {
      getPlans();
    }
  }, [getPlans, plans]);

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (msg) {
      setIsOpen(true);
    }
  }, [msg, setIsOpen]);

  return (
    <>
      <Paper>
        <PopUpSuccess
          isOpenPopUp={isOpen}
          setIsOpenPopUp={setIsOpen}
          message={msg}
          styles={css.popUp}
        />
        <div className={css.containerTable}>
          <MaterialTable
            columns={columns}
            data={plans}
            title={'Planes'}
            onRowClick={(event, rowData: any) => {
              setFormStatus({
                mode: 'VIEW',
                status: true
              });
              setSelectedPlan(rowData._id);
            }}
            actions={[
              {
                icon: 'edit',
                tooltip: 'Editar Plan',
                onClick: (event, rowData: IPlan) => {
                  setFormStatus({
                    mode: 'EDIT',
                    status: true
                  });
                  setSelectedPlan(rowData._id);
                }
              },
              {
                icon: 'delete',
                tooltip: 'Eliminar Plan',
                onClick: (event, rowData) => {
                  setFormStatus({
                    mode: 'DELETE',
                    status: true
                  });
                  setSelectedPlan(rowData._id);
                }
              }
            ]}
            options={{
              actionsColumnIndex: -1,
            }}
            localization={{
              header: {
                actions: 'Acciones'
              }
            }}
          />
        </div>
        <div className={css.buttonAdd}>
          <Button
            variant="text"
            size="medium"
            className={css.buttonDetail}
            onClick={handleNewPlan}
          >
            Agregar Nuevo Plan
          </Button>
        </div>
        <Modal
          open={showForm}
          onClose={closeModal}
        >
          <div className={css.modalContainer}>
            <div className={css.modalBody}>
              {modeForm !== 'DELETE' ? (
                <div className={css.form}>
                  <Form
                    initialValues={planSelected ? planSelected : { services: [{ details: [''] }], }}
                    onSubmit={async values => {
                      if (values) {
                        let payload = {
                          ...values,
                          tableData: undefined,
                          __v: undefined,
                          position: parseInt(values.position, 10)
                        };

                        Object.keys(payload).forEach((key) => {
                          if (payload[key] === undefined)
                            delete payload[key];
                        });
                        if (modeForm === 'EDIT') {
                          editPlan(payload);
                        }
                        if (modeForm === 'ADD') {
                          addPlan(payload);
                        }
                      }
                    }}
                    mutators={{
                      ...arrayMutators,
                      setOnlyNums: (args, state, utils) => {
                        const value = onlyNums(args[0].target.value);
                        utils.changeValue(state, 'position', () => `${value}`);
                      }
                    }}

                  >
                    {({ form, handleSubmit, submitting, values, pristine }) => (
                      <form className={css.formContainer} onSubmit={handleSubmit}>
                        <div className={css.titleForm}>
                          <span>
                            {modeForm === 'EDIT' ? 'Editar Plan' : modeForm === 'ADD' ? 'Agregar Plan' : 'Detalle Plan'}
                          </span>
                        </div>
                        <Field
                          className={css.input}
                          name="name"
                          validate={required}
                          render={TextField}
                          disabled={!!(modeForm === 'VIEW')}
                          label="Nombre"
                        />
                        <Field
                          className={css.input}
                          name="description"
                          validate={required}
                          disabled={!!(modeForm === 'VIEW')}
                          render={TextField}
                          label="Descripción"
                        />
                        <Field
                          className={css.input}
                          name="position"
                          validate={required}
                          disabled={!!(modeForm === 'VIEW')}
                          render={TextField}
                          onChange={form.mutators.setOnlyNums}
                          label="Posición"
                        />
                        <div className={css.containerCheckbox}>
                          <Field
                            name="isImportant"
                            component={Checkbox}
                            disabled={!!(modeForm === 'VIEW')}
                            type="checkbox"
                            label={'Marcar como destacada'}
                          />
                        </div>
                        <div className={css.containerCheckbox}>
                          <Field
                            name="isActive"
                            component={Checkbox}
                            disabled={!!(modeForm === 'VIEW')}
                            type="checkbox"
                            label={'Disponible'}
                          />
                        </div>
                        <FieldArray name="services">
                          {({ fields }) => (
                            <>
                              {fields.map((service, index) => {
                                return (
                                  <div key={service} className={css.services}>
                                    <label className={css.labelService}>Servicio {index + 1}</label>
                                    <div className={css.groupField}>
                                      <Field
                                        className={css.inputServices}
                                        name={`${service}.group`}
                                        component={TextField}
                                        disabled={!!(modeForm === 'VIEW')}
                                        placeholder="Grupo de servicio"
                                      />
                                      {index > 0 && modeForm !== 'VIEW' && (
                                        <IconButton
                                          className={css.iconButtonService}
                                          onClick={() => fields.remove(index)}
                                          style={{ cursor: 'pointer' }}
                                        >
                                          <DeleteIcon />
                                        </IconButton>
                                      )}
                                    </div>
                                    <FieldArray name={`${service}.details`}>
                                      {({ fields }) => (
                                        <>
                                          {fields.map((detail, index) => {
                                            return (
                                              <div key={detail} className={css.details}>
                                                <label className={css.label}>Detalle{index + 1}</label>
                                                <div className={css.groupField}>
                                                  <Field
                                                    className={css.inputDetails}
                                                    name={`${detail}`}
                                                    component={TextField}
                                                    disabled={!!(modeForm === 'VIEW')}
                                                    placeholder="Detalle"
                                                  />
                                                  {index > 0 && modeForm !== 'VIEW' && (
                                                    <IconButton
                                                      className={css.iconButtonDetail}
                                                      onClick={() => fields.remove(index)}
                                                      style={{ cursor: 'pointer' }}
                                                    >
                                                      <DeleteIcon />
                                                    </IconButton>
                                                  )}
                                                </div>
                                              </div>
                                            )
                                          })}
                                          {
                                            modeForm !== 'VIEW' && (
                                              <Button
                                                variant="text"
                                                size="medium"
                                                className={css.buttonDetail}
                                                onClick={() => form.mutators.push(`${service}.details[${fields.length}]`, undefined)}
                                              >
                                                Agregar detalle
                                              </Button>
                                            )
                                          }
                                        </>
                                      )
                                      }
                                    </FieldArray>
                                  </div>
                                )
                              })}
                              {
                                modeForm !== 'VIEW' && (
                                  <Button
                                    variant="text"
                                    size="medium"
                                    className={css.buttonService}
                                    onClick={() => form.mutators.push(`services[${fields.length}].details[0]`, undefined)}
                                  >
                                    Agregar nuevo servicio
                                  </Button>
                                )
                              }
                            </>
                          )
                          }
                        </FieldArray>
                        {modeForm !== 'VIEW' && (
                          <Button
                            variant="text"
                            size="medium"
                            onClick={handleSubmit}
                            disabled={submitting || pristine}
                            className={cx(css.buttonForm, {
                              [css.disabledButton]: submitting || pristine
                            })}
                            test-id="create-account-influencer-button"
                          >
                            {
                              submitting
                                ? (<CircularProgress size={10} />)
                                : modeForm === 'EDIT' ? 'Editar Plan' : modeForm === 'ADD' ? 'Agregar Plan' : 'Detalle Plan'
                            }
                          </Button>
                        )}
                      </form>
                    )}
                  </Form>
                </div>
              ) : (
                <div className={css.containerQuestion}>
                  <div className={css.title}>
                    {`¿Esta seguro de eliminar el Plan ${planSelected && planSelected.name}?`}
                  </div>
                  <div className={css.containerButton}>
                    <Button
                      variant="text"
                      size="medium"
                      className={css.buttonDetail}
                      onClick={() => deletePlan(planSelected ? planSelected._id : '')}
                    >
                      Eliminar Plan
                    </Button>
                    <Button
                      variant="text"
                      size="medium"
                      className={css.buttonDetail}
                      onClick={closeModal}
                    >
                      Cancelar
                    </Button>
                  </div>
                </div>
              )
              }
            </div>
          </div>
        </Modal>
      </Paper>
    </>
  )
}

export default PlanesTable;