import React, { useEffect, useContext } from 'react';
import TextField from '../../../../shared/form/text-field';
import SelectField from '../../../../shared/form/select-field';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Checkbox from '../../../../shared/form/checkbox';
import Button from '@material-ui/core/Button';
import css from './form-one.module.css';
import { Field, Form } from 'react-final-form';
import { useHistory } from 'react-router';
import Footer from '../../../../layouts/public/footer';
import { beneficiosOptions, localidadesOptions, planesOptions } from '../../../../../helpers/data';
import { onlyNums } from '../../../../../helpers/forms/normalize';
import { composeValidators, email, minLength, required } from '../../../../../helpers/forms/validators';
import createDecorator from 'final-form-focus'
import { CircularProgress } from '@material-ui/core';
import clienteAxios from '../../../../../config/axios';
import { parserValues } from '../../../../../helpers/utils';
import { PlanTypeContext } from '../../../../../context/planTypeContext';

const focusOnError = createDecorator();
//FormOne -> Para mi Form

const FormOne: React.FC = () => {

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [])

  const { planTypeState } = useContext(PlanTypeContext);

  const history = useHistory();
  return (
    <>
      <div className={css.headerContainer}>
        <IconButton onClick={() => history.goBack()}>
          <div className={css.imgContainer}>
            <ArrowBackIcon style={{ fill: "white" }} />
          </div>
        </IconButton>
        <span className={css.headerTitle}>Para mi</span>
      </div>
      <div className={css.containerForm}>
        <Form
          initialValues={{ Plan: planTypeState.planSelected }}
          onSubmit={async (values: any) => {
            if (values) {
              const parsedValues = parserValues(values);
              const response = await clienteAxios.post('/api/email/send', parsedValues);
              if (response && response.status === 200) {
                return history.push('/success');
              }
            }
          }}
          decorators={[focusOnError]}
          mutators={{
            setAreaCode: (args, state, utils) => {
              const value = onlyNums(args[0].target.value)
              if (args[0].target.value.length === 1 && args[0].target.value === '0') {
                utils.changeValue(state, 'CodigoArea', () => `${value.toString()}`);
              } else {
                utils.changeValue(state, 'CodigoArea', () => `0${value.toString()}`);
              }
            },
            setPhoneNumber: (args, state, utils) => {
              const value = onlyNums(args[0].target.value);
              const prefix = value.toString().substr(0, 2);
              if (prefix === '15') {
                utils.changeValue(state, 'Telefono', () => `${value.toString()}`);
              } else {
                utils.changeValue(state, 'Telefono', () => `15${value.toString()}`);
              }
            }
          }}
        >
          {({ form, handleSubmit, submitting, values }) => {
            console.log(values);
            return (
              <form className={css.formContainer} onSubmit={handleSubmit}>
                <div className={css.titleForm}>
                  <span>Completa con tus datos los campos solicitados </span>
                </div>
                <div className={css.selectPlan}>
                  <Field
                    className={css.input}
                    name="Plan"
                    validate={required}
                    render={SelectField}
                    options={planesOptions}
                    label="Seleccioná un plan"
                  />
                </div>
                <Field
                  className={css.input}
                  name="Nombre"
                  validate={required}
                  render={TextField}
                  label="Ingresá tu nombre"
                />
                <Field
                  className={css.input}
                  name="Edad"
                  validate={required}
                  render={TextField}
                  label="Ingresá tu edad"
                />
                <Field
                  className={css.input}
                  name="Email"
                  validate={composeValidators(required, email)}
                  render={TextField}
                  label="Ingresá tu email"
                />
                <div className={css.phoneInputs}>
                  <Field
                    className={css.inputAreaCode}
                    name="CodigoArea"
                    validate={composeValidators(required, minLength(3))}
                    isNumberField={true}
                    labelNumber={'0'}
                    onChange={form.mutators.setAreaCode}
                    render={TextField}
                    label="Código de Area"
                  />
                  <Field
                    className={css.inputNumber}
                    name="Telefono"
                    validate={composeValidators(required, minLength(6))}
                    isNumberField={true}
                    labelNumber={'15'}
                    onChange={form.mutators.setPhoneNumber}
                    render={TextField}
                    label="Número telefonico"
                  />
                </div>
                <div className={css.selectInput}>
                  <Field
                    className={css.input}
                    name="Localidad"
                    validate={required}
                    render={SelectField}
                    options={localidadesOptions}
                    label="Seleccioná tu localidad"
                  />
                </div>
                <div className={css.selectInput}>
                  <Field
                    className={css.input}
                    name="Beneficios"
                    render={SelectField}
                    multipleSelection={true}
                    errorStyle={css.errorText}
                    options={beneficiosOptions}
                    label="Beneficios opcionales"
                    form={form}
                  />
                </div>
                <div className={css.termsAndPoliciesContainer}>
                  <button
                    onClick={() => history.push('/terminos-y-politicas')}
                    className={css.titleTermsAndPolicies}>
                    Leer terminos y politicas de privacidad
                  </button>
                  <div className={css.containerCheckbox}>
                    <Field
                      name="Politicas"
                      component={Checkbox}
                      validate={required}
                      type="checkbox"
                      label="Estoy de acuerdo con los terminos y politicas de privacidad"
                      testId="terms-and-conditions-checkbox"
                    />
                  </div>
                </div>
                <Button
                  onClick={handleSubmit}
                  variant="text"
                  size="medium"
                  className={css.buttonForm}
                  test-id="create-account-influencer-button"
                >
                  {submitting ? (<CircularProgress size={10} />) : 'Solicitar cotización'}
                </Button>
              </form>
            )
          }}
        </Form>
      </div>
      <Footer />
    </>
  )
};


export default FormOne;