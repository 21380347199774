import React from 'react';
import MuiAlert, { AlertProps, Color } from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

interface PopUpProps {
  isOpenPopUp: boolean;
  setIsOpenPopUp: (isOpenPopUp: boolean) => void;
  message: string;
  styles: string;
  severity?: Color;
}

const PopUpSuccess: React.FC<PopUpProps> = (props) => {
  const {
    isOpenPopUp,
    setIsOpenPopUp,
    message,
    styles,
    severity
  } = props;

  const handleClosePopUp = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setIsOpenPopUp(false);
  };

  return (
    <>
      <Snackbar open={isOpenPopUp} autoHideDuration={2500} onClose={handleClosePopUp} className={styles}>
        <Alert onClose={handleClosePopUp} severity={severity || 'success'}>
          {message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default PopUpSuccess;
