import tokenAuth from '../../config/tokenAuth';
import {
    SUCCESS_LOGIN,
    FAILED_LOGIN,
    GET_USER,
    FAILED_USER,
    LOGOUT
} from './authTypes';

const reducer = (state: any, action: any) => {
    switch (action.type) {
        case SUCCESS_LOGIN:
            localStorage.setItem('token', action.payload.token);
            tokenAuth(action.payload.token);
            return {
                ...state,
                auth: true,
                token: action.payload.token,
                user: action.payload.user,
                msg: null
            }
        case FAILED_LOGIN:
            console.log('payload', action.payload);
            return {
                ...state,
                token: null,
                msg: action.payload.msg
            }
        case GET_USER:
            return {
                ...state,
                token: action.payload.token,
                user: action.payload.user,
                permissions: action.payload.responseActions,
                rol: action.payload.user.rol,
                auth: true
            }
        case LOGOUT:
        case FAILED_USER:
            localStorage.removeItem('token');
            return {
                ...state,
                user: null,
                rol: null,
                token: null,
                auth: false
            }
        default:
            return state
    }
}

export default reducer;