import css from './checkbox.module.css';
import { FieldRenderProps } from 'react-final-form';
import CheckboxMaterial from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import cx from 'classnames';

interface Props extends FieldRenderProps<string, HTMLElement> {
  disabled?: boolean;
  label: string;
  testId: string;
  className?: string;
  labelStyle?: string;
}

const CheckboxField = ({
  disabled,
  input,
  label,
  testId,
  className,
  labelStyle,
  meta: { touched, error, submitFailed },
  ...custom
}: Props) => (
  <div className={cx(css.formControl, className)} test-id={testId}>
    <FormControlLabel
      control={
        <CheckboxMaterial
          classes={{
            root: disabled
              ? css.checkColorDisabled
              : css.checkColor,
          }}
          color={'secondary'}
          disabled={disabled}
          checked={input.checked}
          onChange={input.onChange}
          {...custom}
        />
      }
      label={(<div className={cx(css.label, labelStyle)}>{label}</div>)}
    />
    <div className={css.error}>
      {(touched && error && submitFailed) && (error)}
    </div>
  </div>
);

export default CheckboxField;
