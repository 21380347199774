import {
  GET_BANNER,
  EDIT_BANNER,
} from './bannerTypes';


const reducer = (state: any, action: any) => {
  switch (action.type) {
    case EDIT_BANNER:
      return {
        ...state,
        banner: action.payload,
        msg: 'Banner editado correctamente',
        showForm: false,
        modeForm: ''
      }
    case GET_BANNER:
      return {
        ...state,
        banner: action.payload[0]
      }
    default:
      return state
  }
};

export default reducer;