import React from 'react';

interface StateContext {
    planSelected: string
};


interface Context {
    planTypeState: StateContext;
    setPlanTypeState: (params?: any) => void;
};

const initialState = {
    planSelected: ''
};

export const initialContext = { planTypeState: initialState, setPlanTypeState: () => ({}) };

export const PlanTypeContext = React.createContext<Context>(initialContext);

export const PlanStateProvider = (props: any) => {
    const [planTypeState, setPlanTypeState] = React.useState<StateContext>(initialState);

    return (
        <PlanTypeContext.Provider value={{ planTypeState, setPlanTypeState }}>
            {props.children}
        </PlanTypeContext.Provider>
    );
};
