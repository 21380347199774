import React, { useEffect } from 'react';
import css from './success.screen.module.css';
import { useHistory } from 'react-router-dom';
import { ReactComponent as CheckBoxIcon } from '../../../../assets/checkboxFinal.svg';
import Button from '@material-ui/core/Button';

const SuccessScreen: React.FC = () => {

  const history = useHistory();
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [])

  return (
    <div className={css.successScreenContainer}>
      <div className={css.containerMessage}>
        <div className={css.containerLogoCheck}>
          <CheckBoxIcon />
        </div>
        <div className={css.containerText}>
          <p>¡Sus datos fueron enviados correctamente!</p>
          <p>Dentro de las próximas 24hs hábiles un asesor se contactará.</p>
        </div>
        <div className={css.backButton}>
          <Button
            variant="text"
            size="medium"
            onClick={() => history.push('/')}
            className={css.button}
            test-id="continuar-button"
          >
            Continuar
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SuccessScreen;