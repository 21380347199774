import { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import AuthContext from '../../../context/auth/authContext';


const CheckClient = ({ component: Component, ...props }: any) => {

  const authContext = useContext(AuthContext);
  const { token } = authContext;

  return (
    <Route {...props} render={props => !token ? (
      <Redirect to="/login" />
    ) : (
      <Component {...props} />
    )} />
  )
}

export default CheckClient;