export const planesData = [
    'Servicios  de salud',
    'Prácticas simples y especializadas',
    'Prácticas de alta complejidad',
    'Odontología',
    'Prestaciones a domicilio',
    'AMCE',
    'Servicios sociales'
];

export const localidadesOptions = [
    { value: 'Aldao', label: 'Aldao' },
    { value: 'Capitan Bermudez', label: 'Capitán Bermudez' },
    { value: 'Fray Luis Beltrán', label: 'Fray Luis Beltrán' },
    { value: 'Puerto General San Martín', label: 'Puerto General San Martín' },
    { value: 'San Lorenzo', label: 'San Lorenzo' },
    { value: 'Ricardone', label: 'Ricardone' },
    { value: 'Timbúes', label: 'Timbúes' },
    { value: 'Otras localidades', label: 'Otras localidades' },
];

export const cantidadHijosOptions = [
    { value: '1', label: '1 hijo/a' },
    { value: '2', label: '2 hijos/as' },
    { value: '3', label: '3 hijos/as' },
    { value: '4', label: '4 hijos/as' },
    { value: '5', label: '5 hijos/as' },
    { value: '6', label: '6 hijos/as' }
];

export const planesOptions = [
    { value: 'Plan Clásico', label: 'Plan Clásico' },
    { value: 'Plan Premium', label: 'Plan Premium' },
    { value: 'Plan Superior', label: 'Plan Superior' },
    { value: 'Plan Inicial', label: 'Plan Inicial' },
    { value: 'Plan Base', label: 'Plan Base' }
];

export const beneficiosOptions = [
    { value: '50% off en farmacias', label: '50% off en farmacias' },
    { value: 'Sepelio', label: 'Sepelio' },
    { value: 'Memorial', label: 'Memorial' },
    { value: 'Subsidio por fallecimiento', label: 'Subsidio por fallecimiento' }
];


export const planesDetail = [
    {
        type: 'Clásico',
        items: [
            {
                title: 'Servicios de salud',
                details: [
                    'Consulta en centro médico propio',
                    'Enfermería en centro médico propio',
                    'Enfermería en domicilio'
                ]
            },
            {
                title: 'Prácticas simples y especializadas',
                details: [
                    'Laboratorio simple y especializado',
                    'Ecografías',
                    'Eco doppler',
                    'Radiografías simples y contrastadas',
                    'Senografías-Pap',
                    'Anatomía patológica',
                    'Kinesiología y fisiatría'
                ]
            },
            {
                title: 'Práctica de alta complejidad',
                details: [
                    'Tomografías en consultorios contratados',
                    'Resonancias en consultorios contratados',
                ]
            },
            {
                title: 'Odontología',
                details: [
                    'Consultas',
                    'Urgencias y tratamientos',
                    'Prótesis y ortodoncias'
                ]
            },
            {
                title: 'Prestaciones a domicilio',
                details: [
                    'Urgencias odontológicas',
                    'Ecografías',
                    'Kinesiología'
                ]
            },
            {
                title: 'AMCE',
                details: [
                    'Emergencias médicas',
                    'Médico a domicilio',
                    'SIEM (Cobertura Nacional e Internacional)'
                ]
            },
            {
                title: 'Servicios Sociales',
                details: [
                    'Ambulancia de traslado',
                    'Remises para traslados médicos',
                    'Préstamos de elementos ortopédicos y nebulizadores',
                    'Descuento en ópticas',
                    'Coches para casamiento',
                    'Regalo Bodas de Oro',
                    'Ajuar para recién nacido'
                ]
            }
        ]
    },
    {
        type: 'Superior',
        items: [
            {
                title: 'Servicios de salud',
                details: [
                    'Consulta en centro médico propio',
                    'Consulta en consultorios contratados',
                    'Enfermería en centro médico propio',
                    'Enfermería en domicilio'
                ]
            },
            {
                title: 'Prácticas simples y especializadas',
                details: [
                    'Laboratorio simple y especializado',
                    'Ecografías',
                    'Eco doppler',
                    'Radiografías simples y contrastadas',
                    'Senografías-Pap',
                    'Anatomía patológica',
                    'Kinesiología y fisiatría'
                ]
            },
            {
                title: 'Práctica de alta complejidad',
                details: [
                    'Tomografías en consultorios contratados',
                    'Resonancias en consultorios contratados',
                ]
            },
            {
                title: 'Odontología',
                details: [
                    'Consultas',
                    'Urgencias y tratamientos',
                    'Prótesis y ortodoncias'
                ]
            },
            {
                title: 'Prestaciones a domicilio',
                details: [
                    'Urgencias odontológicas',
                    'Ecografías',
                    'Radiografías',
                    'Kinesiología'
                ]
            },
            {
                title: 'AMCE',
                details: [
                    'Emergencias médicas',
                    'Médico a domicilio',
                    'SIEM (Cobertura Nacional e Internacional)'
                ]
            },
            {
                title: 'Servicios Sociales',
                details: [
                    'Ambulancia de traslado',
                    'Remises para traslados médicos',
                    'Préstamos de elementos ortopédicos y nebulizadores',
                    'Descuento en ópticas',
                    'Coches para casamiento',
                    'Regalo Bodas de Oro',
                    'Ajuar para recién nacido'
                ]
            }
        ]
    },
    {
        type: 'Premium',
        items: [
            {
                title: 'Servicios de salud',
                details: [
                    'Consulta en centro médico propio',
                    'Consulta en consultorios contratados',
                    'Enfermería en centro médico propio',
                    'Enfermería en domicilio'
                ]
            },
            {
                title: 'Prácticas simples y especializadas',
                details: [
                    'Laboratorio simple y especializado',
                    'Ecografías',
                    'Eco doppler',
                    'Radiografías simples y contrastadas',
                    'Senografías-Pap',
                    'Anatomía patológica',
                    'Kinesiología y fisiatría'
                ]
            },
            {
                title: 'Práctica de alta complejidad',
                details: [
                    'Tomografías en consultorios contratados',
                    'Resonancias en consultorios contratados',
                ]
            },
            {
                title: 'Odontología',
                details: [
                    'Consultas',
                    'Urgencias y tratamientos',
                    'Prótesis y ortodoncias'
                ]
            },
            {
                title: 'Prestaciones a domicilio',
                details: [
                    'Urgencias odontológicas',
                    'Ecografías',
                    'Radiografías',
                    'Kinesiología'
                ]
            },
            {
                title: 'AMCE',
                details: [
                    'Emergencias médicas',
                    'Médico a domicilio',
                    'SIEM (Cobertura Nacional e Internacional)'
                ]
            },
            {
                title: 'Servicios Sociales',
                details: [
                    'Ambulancia de traslado',
                    'Remises para traslados médicos',
                    'Préstamos de elementos ortopédicos y nebulizadores',
                    'Descuento en ópticas',
                    'Coches para casamiento',
                    'Regalo Bodas de Oro',
                    'Ajuar para recién nacido'
                ]
            }
        ]
    }
]
