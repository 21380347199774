import { createContext } from 'react';

export interface IAuthContextProps {
    token: string,
    auth: boolean,
    user?: any,
    rol: string,
    msg: string,
    authentication: (data: any) => Promise<void>,
    getToken: (token: string) => void,
    logout: () => void
};

const AuthContext = createContext<IAuthContextProps>({
    token: '',
    auth: false,
    user: undefined,
    rol: '',
    msg: '',
    authentication: (data: any) => data,
    getToken: () => { },
    logout: () => { }

});


export default AuthContext;