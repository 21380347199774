import css from './text-field.module.css';
import { withStyles } from '@material-ui/core/styles';
import TextFieldMaterial from '@material-ui/core/TextField';
import { getColor } from '../../../../helpers/utils';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import InputAdornmentMaterial from '@material-ui/core/InputAdornment';
import { FieldRenderProps } from 'react-final-form';
import cx from 'classnames';
import { IconButton } from '@material-ui/core';
import { useState } from 'react';

interface TextFieldCustomProps extends FieldRenderProps<string, HTMLElement> {
  label: string;
  disabled: boolean;
  isNumberField?: boolean;
  labelNumber?: string;
  errorStyle?: string;
  type: string;
}

const CssTextField = withStyles(() => ({
  root: {
    '& .MuiInput-formControl': {
      border: '1px solid',
      backgroundColor: getColor('--color-white'),
      borderColor: getColor('--color-grey-border'),
      height: 50,
      padding: '10px 10px',
    },
    '& .MuiInputBase-input': {
      fontFamily: 'Montserrat-Light',
      fontSize: '13px !important',
      fontWeight: 600,
      '&:focus': {
        backgroundColor: getColor('--color-white'),
      },
    },
    '& .MuiInput-multiline': {
      minHeight: 36,
    },
    '& .MuiFormLabel': {},
    '& .MuiInput-underline:before': {
      borderBottom: 0,
      content: 'none',
    },
  },
}))(TextFieldMaterial);

const EndInputAdornment = withStyles(() => ({
  root: {
    color: getColor('--color-primary'),
    '& .MuiSvgIcon-root': {
      height: 15,
      width: 15,
    }
  },
})
)(InputAdornmentMaterial);


const CustomTextField = (
  {
    input,
    meta: {
      touched,
      error,
      visited
    },
    label,
    disabled,
    errorStyle,
    isNumberField,
    labelNumber,
    type,
    ...custom
  }: TextFieldCustomProps
) => {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <div className={css.containerTextField}>
      <CssTextField
        error={!!(touched && error)}
        disabled={disabled}
        placeholder={isNumberField && visited ? labelNumber : label}
        inputProps={
          {
            endAdornment: type === 'password' ?
              (<>
                <EndInputAdornment position="end">
                  <IconButton
                    tabIndex={-1}
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                  </IconButton>
                </EndInputAdornment >
              </>
              ) : undefined
          }
        }
        type={type === 'password' ? showPassword ? 'text' : 'password' : type}
        {...input}
        {...custom}
      />
      <div className={cx(css.textFieldGroupError, errorStyle)}>
        {!!(visited && touched && error) && (error)}
      </div>
    </div>
  );
}


export default CustomTextField;
