// eslint-disable-next-line
const regexEmail = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i


export const composeValidators = (...validators: any) => (value: any) =>
    validators.reduce((error: any, validator: any) => error || validator(value), undefined);

export const required = (value: string) => (value ? undefined : "Es un campo requerido");

export const minLength = (minLength: number) => (value: any) =>
    (value.length < minLength ? `Debe tener al menos ${minLength} carácteres` : undefined);

export const email = (value: string) => (value && regexEmail.test(value)
    ? undefined : 'El mail ingresado es incorrecto');