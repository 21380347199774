import React, { useEffect } from 'react';
import css from './cotiza.module.css';
import ImgForYou1 from '../../../../assets/img1.png';
import ImgForYou2 from '../../../../assets/img2.png';
import ImgForYou3 from '../../../../assets/img3.png';
import ImgForYou4 from '../../../../assets/img4.png';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PlanTypeBox from '../../../shared/plan-type-box';
import { useHistory } from 'react-router';
import Footer from '../../../layouts/public/footer';

const CotizaScreen: React.FC = (props) => {

  const history = useHistory();

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  return (
    <>
      <div className={css.headerContainer}>
        <IconButton onClick={() => history.goBack()}>
          <div className={css.imgContainer}>
            <ArrowBackIcon style={{ fill: "white" }} />
          </div>
        </IconButton>
        <span className={css.headerTitle}>Elegí tu plan</span>
      </div>
      <div className={css.onePlanForYouContainer}>
        <div className={css.planForYouTitle}>Tenemos un plan para vos</div>
        <div className={css.planForYouSubtitle}>
          <p>Elegí tu grupo familiar y cotizá la mejor cobertura que se adapte a vos.</p>
          <p>Te contactaremos a la brevedad para ayudarte en lo que necesites.</p>
        </div>
        <div className={css.containerPlans}>
          <button
            className={css.itemPlan}
            onClick={() => history.push('/tipos/para-mi')}
          >
            <PlanTypeBox
              text={'Para mi'}
              imageDesktop={ImgForYou1}
              imageMobile={ImgForYou1}
            />
          </button>
          <button
            className={css.itemPlan}
            onClick={() => history.push('/tipos/para-mi-y-mi-pareja')}
          >
            <PlanTypeBox
              text={'Para mi y mi pareja'}
              imageDesktop={ImgForYou2}
              imageMobile={ImgForYou2}
            />
          </button>
          <button
            className={css.itemPlan}
            onClick={() => history.push('/tipos/para-mi-y-mis-hijos')}
          >
            <PlanTypeBox
              text={'Para mi y mis hijos'}
              imageDesktop={ImgForYou3}
              imageMobile={ImgForYou3}
            />
          </button>
          <button
            className={css.itemPlan}
            onClick={() => history.push('/tipos/para-mi-y-mi-familia')}
          >
            <PlanTypeBox
              text={'Para mi y mi familia'}
              imageDesktop={ImgForYou4}
              imageMobile={ImgForYou4}
            />
          </button>
        </div>
      </div>
      <Footer />
    </>
  )
}


export default CotizaScreen;