import React from 'react';
import HomeComponent from './home';
import PublicLayout from '../../layouts/public';
import {
	Switch,
	Route
} from 'react-router-dom';
import PlanDetail from './plan-detail';
import FormOne from './plan-type/form-one';
import FormTwo from './plan-type/form-two';
import FormThree from './plan-type/form-three';
import FormFour from './plan-type/form-four';
import TermsAndConditions from './terms-and-conditions/terms-and-conditions';
import CotizaScreen from './cotiza/cotiza';
import SuccessScreen from './success-screen/success-screen';
import { PlanStateProvider } from '../../../context/planTypeContext';


const ClientRoutes: React.FC = () => {
	return (
		<PlanStateProvider>
			<PublicLayout>
				<Switch>
					<Route exact={true} path='/' component={HomeComponent} />
					<Route exact={true} path='/planes/:type' component={PlanDetail} />
					<Route exact={true} path='/tipos/para-mi' component={FormOne} />
					<Route exact={true} path='/tipos/para-mi-y-mi-familia' component={FormTwo} />
					<Route exact={true} path='/tipos/para-mi-y-mis-hijos' component={FormFour} />
					<Route exact={true} path='/tipos/para-mi-y-mi-pareja' component={FormThree} />
					<Route exact={true} path='/terminos-y-politicas' component={TermsAndConditions} />
					<Route exact={true} path='/tipos' component={CotizaScreen} />
					<Route exact={true} path='/success' component={SuccessScreen} />
				</Switch>
			</PublicLayout>
		</PlanStateProvider>
	)
}

export default ClientRoutes;