import React, { useContext } from "react";
import css from "./plan-box.module.css";
import cx from "classnames";
import Button from "@material-ui/core/Button";
import { ReactComponent as CheckBoxIcon } from "../../../assets/Checkbox.svg";
import { PlanTypeContext } from "../../../context/planTypeContext";
import { useHistory } from "react-router";
import { IPlan } from "../../../context/planes/planesContext";

interface Props {
  plan: IPlan;
}

const PlanBox: React.FC<Props> = (props: Props) => {
  const { setPlanTypeState } = useContext(PlanTypeContext);

  const { plan } = props;

  const renderItems = (items: string[]) => (
    <div className={css.containerItems}>
      {items.map((item) => (
        <>
          <div className={css.item}>
            <div
              className={cx(css.itemText, {
                [css.regularPlanText]: !plan.isImportant,
                [css.importantPlanText]: plan.isImportant,
              })}
            >
              {item}
            </div>
            <div className={css.iconCheck}>
              <CheckBoxIcon />
            </div>
          </div>
          <div className={css.divisorLine} />
        </>
      ))}
    </div>
  );

  const history = useHistory();

  const items = plan.services.map((service) => service.group);

  return (
    <div className={css.planBoxContainer}>
      <div
        className={cx(css.title, {
          [css.regularPlanTitle]: !plan.isImportant,
          [css.importantPlanTitle]: plan.isImportant,
        })}
      >
        Plan {plan.name}
      </div>
      <div
        className={cx(css.subtitle, {
          [css.regularPlanTitle]: !plan.isImportant,
          [css.importantPlanTitle]: plan.isImportant,
        })}
      >
        {`${plan.description}`}
      </div>
      {renderItems(items)}
      <div className={css.containerButtons}>
        <div className={css.buttonShowMore}>
          {plan && plan.name === "Farmacias" ? (
            <Button
              variant="text"
              size="medium"
              className={cx(css.buttonForm, {
                [css.importantButton]: plan.isImportant,
              })}
              onClick={() =>
                window.location.replace(
                  "https://grupoprevincasl.com.ar/50-off-en-farmacias/"
                )
              }
              test-id="create-account-influencer-button"
            >
              Ver más
            </Button>
          ) : (
            <Button
              variant="text"
              size="medium"
              className={cx(css.buttonForm, {
                [css.importantButton]: plan.isImportant,
              })}
              onClick={() => history.push(`/planes/${plan.name}`)}
              test-id="create-account-influencer-button"
            >
              Ver más
            </Button>
          )}
        </div>
        <div className={css.cotizaButton}>
          <Button
            variant="text"
            type={"button"}
            onClick={() => {
              setPlanTypeState({ planSelected: `Plan ${plan.name}` });
              history.push("/tipos");
            }}
            size="medium"
            className={css.buttonCotiza}
            test-id="create-account-influencer-button"
          >
            Cotizá ahora
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PlanBox;
