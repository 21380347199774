export const onlyNums = (num: string | number) => {
    let value = 0;
    if (typeof num === 'number') {
        value = num;
    } else if (typeof num === 'string') {
        const onlyNum = `${num}`.replace(/[^0-9]+/g, '');
        value = isNaN(parseInt(onlyNum, 10)) ? 0 : parseInt(onlyNum, 10);
    }
    return value;
};
