import React from 'react';
import AdminLayout from '../../layouts/admin';
import {
    Switch,
    Route,
} from 'react-router-dom';
import Planes from './planes';
import BannerForm from './banners/banners';


const AdminRoutes: React.FC = () => {
    return (
        <AdminLayout>
            <Switch>
                <Route exact={true} path="/admin/planes" component={Planes} />
                <Route exact={true} path="/admin/banner" component={BannerForm} />
            </Switch>
        </AdminLayout>
    )
}

export default AdminRoutes;