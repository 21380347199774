import React from "react";
import PlanBox from "../../../shared/plan-box";
import plans from "./memoriales.json";
import css from './memoriales.module.css';
// import css from '../home/home.module.css'
import cx from 'classnames';

const MemoCards: React.FC = () => {
  console.log(plans);
  const isLastPosition = plans.map(plans => plans.position).sort((a, b) => b - a)[0];
  console.log(isLastPosition)
  return (
    <div className={css.ourPlansContainer}>
      <div className={css.ourPlansSubtitle}>Servicios de Cobertura por Fallecimiento</div>
      <div className={css.boxesContainer}>
        {plans.map((plan) => (
          <>
          <div
            // className={cx({
            //   [css.regularContainer]: !plan.isImportant,
            //   [css.importantContainer]: plan.isImportant,
            //   [css.lastContainer]: plan.position === isLastPosition,
            // })}
            className={css.regularContainer}
          >
            <PlanBox plan={plan} />
          </div>
            <br/>
            </>
        ))}
      </div>
    </div>
  );
}

export default MemoCards;
