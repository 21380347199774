import { useReducer } from 'react';
import PlanContext, { FormPayload, IPlan } from './planesContext';
import PlanReducer from './planesReducer';
import clientAxios from '../../config/axios';
import {
  SET_FORM_STATUS,
  GET_PLANS,
  ADD_PLAN,
  EDIT_PLAN,
  DELETE_PLAN,
  SELECT_PLAN,
  CLEAN_SELECT,
} from './planesTypes';

const PlanState = (props: any) => {
  const initialState = {
    plans: [],
    showForm: false,
    modeForm: '',
    planSelected: undefined,
    msg: null
  }

  const [state, dispatch] = useReducer(PlanReducer, initialState);

  // CRUD FUNCTIONS



  const setFormStatus = (payload: FormPayload) => {
    dispatch({
      type: SET_FORM_STATUS,
      payload
    })
  };

  const getPlans = async () => {
    try {
      const response = await clientAxios.get('/api/admin/plans');
      console.log(response);
      dispatch({
        type: GET_PLANS,
        payload: response.data.data
      })
    } catch (err) {
      console.log(err);
    }
  }

  const addPlan = async (data: IPlan) => {
    try {
      const response = await clientAxios.post('/api/admin/plans', data);
      console.log('responseADD', response);
      dispatch({
        type: ADD_PLAN,
        payload: response.data.data
      })
    } catch (err: any) {
      console.log(err);
    }
  }

  const setSelectedPlan = async (payload: string) => {
    dispatch({
      type: SELECT_PLAN,
      payload
    })
  }

  const cleanSelectPlan = () => {
    dispatch({
      type: CLEAN_SELECT
    })
  }

  const editPlan = async (payload: Partial<IPlan>) => {
    try {
      const response = await clientAxios.put(`/api/admin/plans/${payload._id}`, payload);
      console.log('responseEDIT', response);
      dispatch({
        type: EDIT_PLAN,
        payload: response.data.data
      })
    }
    catch (err) {
      console.log(err)
    }
  }

  const deletePlan = async (payload: string) => {
    try {
      console.log(payload);
      const response = await clientAxios.patch(`/api/admin/plans/${payload}`);
      dispatch({
        type: DELETE_PLAN,
        payload: response.data
      })
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <PlanContext.Provider
      value={{
        plans: state.plans,
        showForm: state.showForm,
        modeForm: state.modeForm,
        planSelected: state.planSelected,
        msg: state.msg,
        getPlans,
        editPlan,
        addPlan,
        deletePlan,
        cleanSelectPlan,
        setFormStatus,
        setSelectedPlan
      }}
    >
      {props.children}
    </PlanContext.Provider>
  )
}


export default PlanState;