import { Button, CircularProgress } from '@material-ui/core';
import createDecorator from 'final-form-focus';
import { useHistory } from 'react-router-dom';
import css from './login.module.css';
import React, { useContext, useEffect, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { required } from '../../../../helpers/forms/validators';
import TextField from '../../../shared/form/text-field';
import AuthContext from '../../../../context/auth/authContext';

const focusOnError = createDecorator();

const Login: React.FC = () => {

  const authContext = useContext(AuthContext);
  const { auth, msg, authentication } = authContext;
  const [invalidMsg, setInvalidMsg] = useState('');
  const history = useHistory();


  useEffect(() => {
    if (msg) {
      setInvalidMsg(msg);
    }
  }, [msg]);

  useEffect(() => {
    if (auth) {
      return history.push('/admin');
    }
  }, [auth, history]);

  return (
    <div className={css.loginContainer}>
      <div className={css.titleLogin}>
        Admin Previnca Landing
      </div>
      <div className={css.containerForm}>
        <Form
          onSubmit={(values: any) => {
            authentication({ username: values.username, password: values.password });
          }}
          decorators={[focusOnError]}
        >
          {({ form, handleSubmit, submitting, values }) => (
            <div className={css.formContainer}>
              <form onSubmit={handleSubmit}>
                <Field
                  className={css.input}
                  name="username"
                  validate={required}
                  render={TextField}
                  errorStyle={css.errorText}
                  label="Ingresá tu usuario"
                />
                <Field
                  className={css.input}
                  name="password"
                  validate={required}
                  render={TextField}
                  errorStyle={css.errorText}
                  type="password"
                  label="Ingresá tu contraseña"
                />
                <div className={css.errorContainer}>
                  <span className={css.errorText}>{invalidMsg}</span>
                </div>
                <Button
                  onClick={handleSubmit}
                  variant="text"
                  size="medium"
                  className={css.buttonForm}
                  test-id="create-account-influencer-button"
                >
                  {submitting ? (<CircularProgress size={10} />) : 'Ingresar'}
                </Button>
              </form>
            </div>
          )}
        </Form>
      </div>
    </div >
  )
}

export default Login;