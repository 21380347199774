import { createContext } from 'react';

export interface Service {
  group: string;
  details: string[];
}

export interface FormPayload {
  status: boolean;
  mode: string;
}

export interface IPlan {
  _id: string;
  name: string;
  description: string;
  services: Service[];
  position: number;
  isImportant: boolean;
  isActive: boolean;
  logicDelete: boolean;
}

export interface IPlansContext {
  plans: IPlan[];
  showForm: boolean;
  modeForm: string;
  msg: string;
  planSelected?: IPlan;
  getPlans: () => void;
  setFormStatus: (status: FormPayload) => void;
  setSelectedPlan: (id: string) => void;
  cleanSelectPlan: () => void;
  editPlan: (data: IPlan) => void;
  addPlan: (data: IPlan) => void;
  deletePlan: (id: string) => void;
};

const PlanContext = createContext<IPlansContext>({
  plans: [],
  showForm: false,
  modeForm: '',
  msg: '',
  planSelected: undefined,
  getPlans: () => { },
  setFormStatus: () => { },
  setSelectedPlan: () => { },
  cleanSelectPlan: () => { },
  editPlan: () => { },
  addPlan: () => { },
  deletePlan: () => { },
});


export default PlanContext;