import React from 'react';
import ClientRoutes from './client';
import AdminRoutes from './admin';
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Redirect,
} from "react-router-dom";
import Login from './admin/login';
import CheckClients from '../shared/check-clients';

const AppRouter: React.FC<{}> = () => (
	<Router>
		<Switch>
			<Route path="/login" component={Login} />
			<CheckClients path="/admin" component={AdminRoutes} />
			<Route path="/" component={ClientRoutes} />
			<Redirect to="/" />
		</Switch>
	</Router>
);


export default AppRouter;