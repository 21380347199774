import { useReducer } from 'react';
import BannerContext, { IBanner } from './bannerContext';
import BannerReducer from './bannerReducer';
import clientAxios from '../../config/axios';
import {
  GET_BANNER,
  EDIT_BANNER,
} from './bannerTypes';

const BannerState = (props: any) => {
  const initialState = {
    banner: undefined,
    showForm: false,
    modeForm: '',
    msg: null
  }

  const [state, dispatch] = useReducer(BannerReducer, initialState);

  // CRUD FUNCTIONS
  const getBanner = async () => {
    try {
      const response = await clientAxios.get('/api/admin/banners');
      console.log('RESPONSE', response);
      dispatch({
        type: GET_BANNER,
        payload: response.data.data
      })
    } catch (err) {
      console.log(err);
    }
  }

  const editBanner = async (payload: Partial<IBanner>) => {
    try {
      const response = await clientAxios.put(`/api/admin/banners/${payload._id}`, payload);
      console.log('responseEDIT', response);
      dispatch({
        type: EDIT_BANNER,
        payload: response.data.data
      })
    }
    catch (err) {
      console.log(err)
    }
  }

  return (
    <BannerContext.Provider
      value={{
        banner: state.banner,
        showForm: state.showForm,
        modeForm: state.modeForm,
        msg: state.msg,
        getBanner,
        editBanner
      }}
    >
      {props.children}
    </BannerContext.Provider>
  )
}


export default BannerState;