import { createContext } from 'react';

export interface Image {
  key: string;
  url: string;
}

export interface IBanner {
  _id: string;
  title: string;
  desktopImage: Image;
  mobileImage: Image;
  isActive: boolean;
  logicDelete: boolean;
}

export interface IBannerContext {
  banner?: IBanner;
  showForm: boolean;
  modeForm: string;
  msg: string;
  getBanner: () => void;
  editBanner: (data: IBanner) => void;
};

const BannerContext = createContext<IBannerContext>({
  banner: undefined,
  showForm: false,
  modeForm: '',
  msg: '',
  getBanner: () => { },
  editBanner: () => { },
});


export default BannerContext;