import AppRouter from './components/routes';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import AuthState from './context/auth/authState';
import PlanesState from './context/planes/planesState';
import BannerState from './context/banner/bannerState';

// TODO: import color from index.css, for some reason it is not working here.
const theme = createMuiTheme({
  typography: {
    fontFamily: [
      'WorkSans-Light',
      'Roboto',
      'sans-serif'
    ].join(','),
  },
  palette: {
    primary: {
      main: '#9E213D', // --color-primary
      dark: '#9E213D',// --color-primary
    },
    secondary: {
      main: '#FFFFFF' // --color-white
    },
    error: {
      main: '#FD0000', // --color-red
    },
    text: {
      disabled: '#00000080',
    }
  },
});

const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <AuthState>
        <PlanesState>
          <BannerState>
            <AppRouter />
          </BannerState>
        </PlanesState>
      </AuthState>
    </ThemeProvider>
  );
}

export default App;
