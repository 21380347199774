import { useMediaQuery } from '@material-ui/core';
import React from 'react';
import { ReactComponent as PrevincaButton } from '../../../assets/btn-previnca.svg';
import css from './plan-type-box.module.css';

interface Props {
  text: string;
  imageMobile: string;
  imageDesktop: string;
}

const PlanTypeBox: React.FC<Props> = (props: Props) => {

  const { text, imageMobile, imageDesktop } = props;
  const isMobile = useMediaQuery('(max-width:860px)');

  return (
    <div className={css.planBoxContainer}>
      <div className={css.planBoxContainerImg}>
        <img
          alt='imagen'
          className={css.imgHeader}
          src={isMobile ? imageMobile : imageDesktop}
        />
      </div>
      <div className={css.iconContainer}>
        <div className={css.imgContainer}>
          <PrevincaButton />
        </div>
      </div>
      <div className={css.textContainer}>
        {text}
      </div>
    </div>
  );
};

export default PlanTypeBox;