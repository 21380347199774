import { Button, CircularProgress, Paper } from '@material-ui/core';
import css from './banners.module.css'
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ImageCropField from '../../../shared/form/image-field/image-crop-field';
import { Field, Form } from 'react-final-form';
import saveFile from '../../../../helpers/forms/saveFile';
import BannerContext from '../../../../context/banner/bannerContext';
import get from 'lodash/get';
import cx from 'classnames';
import { required } from '../../../../helpers/forms/validators';
import PopUpSuccess from '../../../shared/popup-success/popup-success';


const BannerForm: React.FC = () => {

  const bannerContext = useContext(BannerContext);
  const { banner, getBanner, editBanner, msg } = bannerContext;
  const [isFetching, setFetching] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (!banner) {
      getBanner();
    }
  }, [getBanner, banner]);

  useEffect(() => {
    if (msg) {
      setFetching(false);
      setIsOpen(true);
    }
  }, [msg, setIsOpen]);

  const history = useHistory();

  return (
    <Paper>
      <PopUpSuccess
        isOpenPopUp={isOpen}
        setIsOpenPopUp={setIsOpen}
        message={msg}
        styles={css.popUp}
      />
      <div className={css.containerForm}>
        <div className={css.titleForm}>
          Banner Principal
        </div>
        <Form
          initialValues={banner}
          onSubmit={async (values: any) => {
            if (values) {
              setFetching(true);
              const imageDesktopValue = get(values, `desktopImage.file`);
              const isNewDesktop = get(values, `desktopImage.isNew`);
              const imageMobileValue = get(values, `mobileImage.file`);
              const isNewMobile = get(values, `mobileImage.isNew`);
              let desktopImage;
              if (imageDesktopValue && isNewDesktop) {
                const imageDesktopFile: any = await saveFile.toBase64(imageDesktopValue);
                if (imageDesktopFile) {
                  desktopImage = {
                    base64: imageDesktopFile,
                    name: 'imageHeaderDesktop',
                    type: imageDesktopValue.type,
                    isNew: true,
                  };
                }
              }
              let mobileImage;
              if (imageMobileValue && isNewMobile) {
                const imageMobileFile: any = await saveFile.toBase64(imageMobileValue);
                if (imageMobileFile) {
                  mobileImage = {
                    base64: imageMobileFile,
                    name: 'imageHeader',
                    type: imageMobileValue.type,
                    isNew: true,
                  };
                }
              }
              let payload = {
                ...values,
                __v: undefined,
                desktopImage: desktopImage ? desktopImage : values.desktopImage,
                mobileImage: mobileImage ? mobileImage : values.mobileImage
              };
              Object.keys(payload).forEach((key) => {
                if (payload[key] === undefined)
                  delete payload[key];
              });

              editBanner(payload);

              return history.push('/admin/banner');

            }

          }}
        >
          {({ form, handleSubmit, values, submitting, pristine }) => {
            return (
              <form className={css.formContainer} onSubmit={handleSubmit}>
                <div className={css.imagesContainer}>
                  <div className={css.desktopImage}>
                    {
                      !banner
                        ? (<CircularProgress size={20} />)
                        :
                        <Field
                          name="desktopImage"
                          label="Escritorio *"
                          component={ImageCropField}
                          validate={required}
                          aspect={1.78}
                        />
                    }
                  </div>
                  <div>
                    {!banner
                      ? (<CircularProgress size={20} />)
                      :
                      <Field
                        name="mobileImage"
                        label="Móvil *"
                        validate={required}
                        component={ImageCropField}
                        aspect={0.91}
                      />
                    }
                  </div>
                </div>
                <div className={css.buttonContainer}>
                  <Button
                    variant="text"
                    size="medium"
                    onClick={() => {
                      handleSubmit();
                    }}
                    disabled={submitting || pristine}
                    className={cx(css.buttonForm, {
                      [css.disabledButton]: submitting || pristine
                    })}
                    test-id="create-account-influencer-button"
                  >
                    {
                      isFetching
                        ? (<CircularProgress size={20} />)
                        : 'Editar Banner'
                    }
                  </Button>
                </div>
              </form>
            )
          }}
        </Form>
      </div>
    </Paper>
  )


};

export default BannerForm;