import React, { useContext, useEffect } from 'react';
import css from './plan-detail.module.css';
import cx from 'classnames';
import { useHistory } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PlansContext, { Service } from '../../../../context/planes/planesContext';
import Button from '@material-ui/core/Button';
import { ReactComponent as CheckBoxIcon } from '../../../../assets/Checkbox.svg';
import Footer from '../../../layouts/public/footer';
import plansCaramuto from '../memoriales-cards/memoriales.json'

const PlanDetail: React.FC = () => {

  const history = useHistory();

  const plansContext = useContext(PlansContext);

  const { plans, getPlans } = plansContext;
  
  
  const planName = history.location.pathname.split('/')[2];
  console.log(planName)
  useEffect(() => {
    if (plans && plans.length === 0) {
      getPlans();
    }
  }, [getPlans, plans, planName]);

  const getServiciosCaramutoPlans= () => {
    if(planName === 'Subsidio Por Fallecimiento' || planName === 'Sepelio' || planName === 'Memorial'){
      const filterData = plansCaramuto.find(plan => plan.name === planName);
      return filterData;
    }
    const filterData = plans.find(plan => plan.name === planName);
    return filterData;
  }

 const filterData = getServiciosCaramutoPlans();

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  const renderItems = (planesData: Service[]) => (
    <div className={css.containerItems}>
      {planesData.map(item => (
        <>
          <div className={css.item}>
            <div className={cx(css.itemText, {
              [css.regularPlanItemText]: filterData && !filterData.isImportant,
              [css.importantPlanItemText]: filterData && filterData.isImportant
            })}>{item.group}
            </div>
            <div className={css.iconCheck}>
              <CheckBoxIcon />
            </div>
          </div>
          <div className={css.divisorLine} />
          <div className={css.detailText}>
            {item.details.map(detail => {
              if(detail === 'Visita nuestro sitio') {
              return <span className={css.textDetail}>- {detail} <a target='_blank' href='https://grupoprevincasl.com.ar/50-off-en-farmacias/'>Click Aquí</a></span>
              } else if(!detail){
                //SI EL SERVICIO NO TIENE DETALLES SE LO OMITE
                //AÑADIR SERVICIO AL RESTO DE PLANES Y SUBIRLO A HOSTINGER
              } 
              else{
                return <span className={css.textDetail}>- {detail}</span>
              }
            })}
          </div>
        </>
      ))
      }
    </div >
  );

  return (
    <div className={css.planDetail}>
      <div className={css.ourPlansContainer}>
        <IconButton onClick={() => history.goBack()}>
          <div className={css.imgContainer}>
            <ArrowBackIcon style={{ fill: "white" }} />
          </div>
        </IconButton>
        <span className={css.headerTitle}>Nuestros Planes</span>
      </div>
      <div className={cx(css.planBoxContainer, {
        [css.regularPlanContainer]: filterData && !filterData.isImportant,
        [css.importantPlanContainer]: filterData && filterData.isImportant
      })}>
        <div className={cx(css.title, {
          [css.regularPlanTitle]: filterData && !filterData.isImportant,
          [css.importantPlanTitle]: filterData && filterData.isImportant
        })}>
          Plan {planName}
        </div>
        <div className={cx(css.subtitle, {
          [css.regularPlanSubtitle]: filterData && !filterData.isImportant,
          [css.importantPlanSubtitle]: filterData && filterData.isImportant
        })}>
          {filterData && filterData.description}
        </div>
        {renderItems((filterData && filterData.services) || [])}
        <div className={css.containerButtons}>
          <div className={css.cotizaButton}>
            <Button
              variant="text"
              type={'button'}
              onClick={() => history.push('/tipos')}
              size="medium"
              className={css.buttonCotiza}
              test-id="create-account-influencer-button"
            >
              Cotizá ahora
            </Button>
          </div>
        </div>
      </div>
      <div className={css.containerFooter}>
        <Footer />
      </div>
    </div>
  );
};

export default PlanDetail;