import React, { useContext, useEffect } from 'react';
import css from './home.module.css';
import ForMeForm from '../../../shared/form/for-me-form/for-me-form';
import HeaderImage from '../../../../assets/imageHeader.jpg';
import ImgForYou1 from '../../../../assets/img1.png';
import ImgForYou2 from '../../../../assets/img2.png';
import ImgForYou3 from '../../../../assets/img3.png';
import ImgForYou4 from '../../../../assets/img4.png';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Benefit1 from '../../../../assets/benefit1.png';
import Benefit2 from '../../../../assets/benefit2.png';
import Benefit3 from '../../../../assets/benefit3.png';
import Benefit4 from '../../../../assets/benefit4.png';
import PlansContext from '../../../../context/planes/planesContext';
import BannerContext from '../../../../context/banner/bannerContext';
import Benefit5 from '../../../../assets/benefit5.png';
import Benefit6 from '../../../../assets/benefit6.png';
import PlanBox from '../../../shared/plan-box';
import PlanTypeBox from '../../../shared/plan-type-box';
import NavBar from '../../../layouts/public/navbar';
import cx from 'classnames';
import Footer from '../../../layouts/public/footer';
import { useHistory } from 'react-router';
import { Element } from "react-scroll";
import { PlanTypeContext } from '../../../../context/planTypeContext';
import MemoCards from '../memoriales-cards';

const Home: React.FC = (props) => {

  const isMobile = useMediaQuery('(max-width:860px)');
  const isDesktop = useMediaQuery('(min-width: 860px)');
  const plansContext = useContext(PlansContext);
  const bannerContext = useContext(BannerContext);

  const { plans, getPlans } = plansContext;
  const { banner, getBanner } = bannerContext;

  useEffect(() => {
    if (plans && plans.length === 0) {
      getPlans();
    }
    console.log(plans)
  }, [getPlans, plans]);

  useEffect(() => {
    if (!banner) {
      getBanner();
    }
  }, [banner, getBanner]);


  const history = useHistory();

  const { setPlanTypeState } = useContext(PlanTypeContext);
  const isLastPosition = plans.map(plans => plans.position).sort((a, b) => b - a)[0];

  const desktopImage = banner && banner.desktopImage.url;

  const divStyle: any = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexGrow: 1,
    minHeight: '950px',
    width: '100%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundAttachment: 'scroll',
    backgroundSize: 'cover',
    backgroundColor: 'white',
    zIndex: 1000,
    backgroundImage: 'url(' + desktopImage + ')',
  };

  return (
    <div className={css.publicPaper}>
      <div style={isDesktop ? divStyle : { height: '100%', width: '100%' }}>
        {isDesktop && (
          <>
            <div className={css.container}>
              <NavBar />
              <div className={css.containerForm}>
                <div className={css.titleForm}>Tenemos un plan a tu medida</div>
                <div className={css.subtitleForm}>Completá el formulario y recibí tu cotizacion</div>
                <ForMeForm />
              </div>
            </div>
          </>
        )}
        {isMobile && (
          <img
            alt='imagen'
            className={css.imgHeader}
            src={banner ? banner.mobileImage.url : HeaderImage}
          />
        )}
      </div>
      {isMobile && (
        <div className={css.homePaper}>
          <div className={css.homeContainer}>
            <div className={css.titleForm}>Tenemos un plan a tu medida</div>
            <div className={css.subtitleForm}>Completá el formulario y recibí tu cotizacion</div>
            <ForMeForm />
          </div>
        </div>
      )}
      <Element name="Nuestros_Planes">
        <div className={css.ourPlansContainer}>
          <div className={css.ourPlansTitle}>Nuestros planes</div>
          <div className={css.ourPlansSubtitle}>Servicios de salud</div>
          <div className={css.boxesContainer}>
            {plans.map(plan => (
              <>
                <div className={cx({
                  [css.regularContainer]: !plan.isImportant,
                  [css.importantContainer]: plan.isImportant,
                  [css.lastContainer]: plan.position === isLastPosition
                })}>
                  <PlanBox
                    plan={plan}
                  />
                </div>
                <br/>
              </>
            ))}
          </div>
        </div>
      </Element>
      <MemoCards/>
      <Element name="Tenemos_Un_Plan_Para_Vos">
        <div className={css.onePlanForYouContainer}>
          <div className={css.planForYouTitle}>Tenemos un plan para vos</div>
          <div className={css.planForYouSubtitle}>
            <p>Elegí tu grupo familiar y cotizá la mejor cobertura que se adapte a vos.</p>
            <p>Te contactaremos a la brevedad para ayudarte en lo que necesites.</p>
          </div>
          <div className={css.containerPlans}>
            <button
              className={css.itemPlan}
              onClick={() => {
                setPlanTypeState({ planSelected: '' });
                history.push('/tipos/para-mi')
              }}
            >
              <PlanTypeBox
                text={'Para mi'}
                imageDesktop={ImgForYou1}
                imageMobile={ImgForYou1}
              />
            </button>
            <button
              className={css.itemPlan}
              onClick={() => {
                setPlanTypeState({ planSelected: '' });
                history.push('/tipos/para-mi-y-mi-pareja')
              }}
            >
              <PlanTypeBox
                text={'Para mi y mi pareja'}
                imageDesktop={ImgForYou2}
                imageMobile={ImgForYou2}
              />
            </button>
            <button
              className={css.itemPlan}
              onClick={() => {
                setPlanTypeState({ planSelected: '' });
                history.push('/tipos/para-mi-y-mis-hijos')
              }}
            >
              <PlanTypeBox
                text={'Para mi y mis hijos'}
                imageDesktop={ImgForYou3}
                imageMobile={ImgForYou3}
              />
            </button>
            <button
              className={css.itemPlan}
              onClick={() => {
                setPlanTypeState({ planSelected: '' });
                history.push('/tipos/para-mi-y-mi-familia')
              }}
            >
              <PlanTypeBox
                text={'Para mi y mi familia'}
                imageDesktop={ImgForYou4}
                imageMobile={ImgForYou4}
              />
            </button>
          </div>
        </div>
      </Element>
      <div className={css.homeBenefits}>
        <div className={css.homeBenefitsTitle}>Prestaciones a domicilio</div>
        <div className={css.homeBenefitsSubTitle}>Nada mejor que el hogar</div>
        <div className={css.homeBenefitsText}>
          <p>Contamos con prestaciones especificas a domicilio para que no te muevas de tu casa.</p>
        </div>
        <div className={css.containerBenefits}>
          <div className={css.itemBenefit}>
            <div className={css.containerImgBenefit}>
              <img
                alt='imagen'
                className={css.imgBenefits}
                src={Benefit1}
              />
            </div>
            <div className={css.containerText}>
              <span>Urgencias odontológicas </span>
              <div className={css.divisorLine} />
            </div>
          </div>
          <div className={css.itemBenefit}>
            <div className={css.containerImgBenefit}>
              <img
                alt='imagen'
                className={css.imgBenefits}
                src={Benefit2}
              />
            </div>
            <div className={css.containerText}>
              <span>Radiografías</span>
              <div className={css.divisorLine} />
            </div>
          </div>
          <div className={css.itemBenefit}>
            <div className={css.containerImgBenefit}>
              <img
                alt='imagen'
                className={css.imgBenefits}
                src={Benefit3}
              />
            </div>
            <div className={css.containerText}>
              <span>Ecografías</span>
              <div className={css.divisorLine} />
            </div>
          </div>
          <div className={css.itemBenefit}>
            <div className={css.containerImgBenefit}>
              <img
                alt='imagen'
                className={css.imgBenefits}
                src={Benefit4}
              />
            </div>
            <div className={css.containerText}>
              <span>Médicos</span>
              <div className={css.divisorLine} />
            </div>
          </div>
          <div className={css.itemBenefit}>
            <div className={css.containerImgBenefit}>
              <img
                alt='imagen'
                className={css.imgBenefits}
                src={Benefit5}
              />
            </div>
            <div className={css.containerText}>
              <span>Kinesiólogía</span>
              <div className={css.divisorLine} />
            </div>
          </div>
          <div className={css.itemBenefit}>
            <div className={css.containerImgBenefit}>
              <img
                alt='imagen'
                className={css.imgBenefits}
                src={Benefit6}
              />
            </div>
            <div className={css.containerText}>
              <span>Enfermería</span>
              <div className={css.divisorLine} />
            </div>
          </div>
        </div>
      </div>
      <div className={css.containerFooter}>
        <Footer />
      </div>
    </div>
  )
}


export default Home;