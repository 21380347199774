import React, { useRef, useCallback, useState, useMemo } from 'react';
import cx from 'classnames';
import Close from '@material-ui/icons/Close';
import AddPhotoAlternate from '@material-ui/icons/AddPhotoAlternate';
import Button from '@material-ui/core/Button';
import css from './image-crop-field.module.css';
import { FieldRenderProps } from 'react-final-form';
import { getCroppedImg, readFile } from '../../../../helpers/utils';
import Cropper from 'react-easy-crop';
import { Modal, Slider } from '@material-ui/core';

interface Props extends FieldRenderProps<any, HTMLElement> {
  label: string;
  disabled?: boolean;
  testId?: string;
  sizeLimit?: number;
  aspect?: number;
}

const ImageCropField: React.FC<Props> = (props) => {

  const {
    input,
    label,
    disabled,
    meta: {
      touched,
      error,
      submitFailed
    },
    aspect = 1,
    testId,
    sizeLimit = 5120, // 5MB
  } = props;

  const inputEl: any = useRef({ current: {} });
  const [image, setImage] = useState('');

  interface State {
    fileSizeError: string;
  }

  const [fileSizeError, changeFileSizeError] = useState<State['fileSizeError']>('');
  const [open, setOpen] = useState(false);

  const handleInput = useCallback(async (event) => {
    const file = event.target.files[0];
    if (file && file.size > sizeLimit * 1024) {
      changeFileSizeError(`Debe pesar menos de ${sizeLimit / 1024} MB`);
    }
    const img: any = await readFile(file);
    setImage(img);
    setOpen(true);
  }, [sizeLimit, setImage]);

  const removeFile = useCallback((e: any) => {
    e.stopPropagation();
    URL.revokeObjectURL(input.value.url);
    input.onChange(null);
  }, [input]);

  const onClick = useCallback(() => {
    inputEl.current.value = null;
    inputEl.current.click();
  }, [inputEl]);

  const hasError = useMemo(() => (touched && error && submitFailed) || fileSizeError,
    [touched, error, submitFailed, fileSizeError]
  );

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState<number>(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const onCropComplete = useCallback((_, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage: any = await getCroppedImg(
        image,
        croppedAreaPixels,
      );
      croppedImage.name = `${input.name}-${new Date().getTime()}`;
      input.onChange({
        url: URL.createObjectURL(croppedImage),
        file: croppedImage,
        isNew: true,
      });
      closeModal();
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels, image, input]);

  const closeModal = () => {
    setOpen(false);
  };

  return (
    <>
      <div className={cx(css.label, { [css.disableLabel]: disabled })}>{label}</div>
      <div className={css.container} style={{ width: aspect * 200, height: 200 }}>
        <input
          type="file"
          ref={inputEl}
          multiple={false}
          accept="image/png, image/jpeg, image/webp"
          onChange={handleInput}
          className={css.input}
          test-id={testId}
        />
        <button
          className={cx(css.containerPreview, { [css.error]: hasError })}
          onClick={onClick}
          disabled={disabled}
        >
          {
            input.value.url
              ? <img
                alt={input.name}
                className={cx(css.imagePreview, { [css.disableImage]: disabled })}
                src={input.value.url}
              />
              : <AddPhotoAlternate fontSize={'large'} />
          }
        </button>
        {!disabled && (input.value.url || input.value.file) && (
          <button
            className={css.closeButton}
            onClick={removeFile}
            disabled={disabled}
          >
            <Close />
          </button>
        )}
        <div className={css.imageFieldGroupError}>
          {hasError && error}
        </div>
      </div>
      <Modal
        open={open}
        onClose={closeModal}
      >
        <div className={css.modalContainer}>
          <div className={css.modalBody}>
            <div className={css.content}>
              <h1 className={css.title} test-id="change-password-modal-title">
                Ajustar Imagen
              </h1>
              <div className={css.cropImageContainer}>
                <Cropper
                  image={image}
                  crop={crop}
                  zoom={zoom}
                  aspect={aspect}
                  onCropChange={setCrop}
                  onCropComplete={onCropComplete}
                  onZoomChange={setZoom}
                />
              </div>
              <Slider
                value={zoom}
                min={1}
                max={3}
                step={0.1}
                aria-labelledby="Zoom"
                onChange={(e, zoom: any) => setZoom(zoom)}
              />
              <div className={css.confirmModalButtons}>
                <Button
                  variant="text"
                  size="medium"
                  className={css.buttonCancel}
                  onClick={closeModal}
                >
                  Cancelar
                </Button>
                <Button
                  variant="text"
                  size="medium"
                  className={css.buttonAccept}
                  onClick={showCroppedImage}
                >
                  Aceptar
                </Button>
              </div>
            </div >
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ImageCropField;