import React from 'react';
import { animateScroll as scroll } from "react-scroll";
import { ReactComponent as Linkedin } from '../../../../assets/linkedin.svg';
import { ReactComponent as Facebook } from '../../../../assets/facebook.svg';
import { ReactComponent as Instagram } from '../../../../assets/instagram.svg';
import { ReactComponent as LogoPrevinca } from '../../../../assets/logoMenuF.svg';
import css from './footer.module.css';

const Footer: React.FC = () => {

  const scrollToTop = () => {
    scroll.scrollToTop();
  };

  return (
    <footer className={css.footer}>
      <div className={css.container}>
        <div className={css.topContainer}>
          <div className={css.logo}>
            <LogoPrevinca />
          </div>
          <button onClick={scrollToTop}>
            <span className={css.buttonToUp}>Volver arriba</span>
          </button>
          <div className={css.text}>
            <p>Av. San Martín 2098</p>
            <p>San Lorenzo, Santa Fé</p>
            <p>(03476) 422137</p>
            <p className={css.textBold}>www.grupoprevicasl.com.ar</p>
            <p className={css.textBold}>info@grupoprevincasl.com.ar</p>
          </div>
        </div>
        <div className={css.bottomContainer}>
          <span className={css.socialNetworksTitle}>
            Todos los derechos reservados
          </span>
          <a
            href={'https://www.facebook.com/previncasanlorenzo'}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Facebook className={css.socialIcon} />
          </a>
          <a
            href={'https://www.instagram.com/previncasanlorenzo/'}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Instagram className={css.socialIcon} />
          </a>
          <a
            href={'https://ar.linkedin.com/company/previnca-san-lorenzo'}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Linkedin className={css.socialIcon} />
          </a>
        </div>
      </div>
    </footer >
  );
};

export default Footer;