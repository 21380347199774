import {
  SET_FORM_STATUS,
  GET_PLANS,
  ADD_PLAN,
  EDIT_PLAN,
  DELETE_PLAN,
  SELECT_PLAN,
  CLEAN_SELECT,
} from './planesTypes';


const reducer = (state: any, action: any) => {
  switch (action.type) {
    case SET_FORM_STATUS:
      return {
        ...state,
        showForm: action.payload.status,
        modeForm: action.payload.mode
      }
    case ADD_PLAN:
      return {
        ...state,
        plans: [...state.plans, action.payload],
        msg: 'Plan agregado correctamente',
        showForm: false,
        modeForm: ''
      }
    case EDIT_PLAN:
      return {
        ...state,
        plans: state.plans.map((plan: any) => plan._id === action.payload._id ? action.payload : plan),
        msg: 'Plan editado correctamente',
        planSelected: null,
        showForm: false,
        modeForm: ''
      }
    case DELETE_PLAN:
      return {
        ...state,
        plans: state.plans.length === 1 ? [] : state.plans.filter((plan: any) => plan._id === action.payload._id),
        msg: 'Plan eliminado correctamente',
        showForm: false,
        modeForm: ''
      }
    case SELECT_PLAN:
      return {
        ...state,
        planSelected: state.plans.find((plan: any) => plan._id.toString() === action.payload.toString())
      }
    case GET_PLANS:
      return {
        ...state,
        plans: action.payload
      }
    case CLEAN_SELECT:
      return {
        ...state,
        planSelected: null,
        showForm: false,
        modeForm: ''
      }
    default:
      return state
  }
};

export default reducer;