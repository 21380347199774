import React from 'react';
import css from './public.module.css';
import NavBar from './navbar';
import { useMediaQuery } from '@material-ui/core';
import { useLocation } from 'react-router';
import cx from 'classnames';

const PublicLayout: React.FC = (props) => {
	const isMobile = useMediaQuery('(max-width:860px)');
	const location = useLocation();
	const isPlanesDetailRoute = !!(
		(location.pathname.includes('/planes/')) ||
		(location.pathname === '/tipos/para-mi') ||
		(location.pathname === '/tipos/para-mi-y-mi-familia') ||
		(location.pathname === '/tipos/para-mi-y-mi-pareja') ||
		(location.pathname === '/tipos/para-mi-y-mis-hijos') ||
		(location.pathname === '/terminos-y-politicas') ||
		(location.pathname === '/success') ||
		((location.pathname === '/tipos') && isMobile)
	)
	return (
		<div className={cx({
			[css.publicContainer]: !(location.pathname === '/success'),
		})}>
			{isMobile && !isPlanesDetailRoute && (
				<NavBar />
			)}
			{props.children}
		</div>
	)
};

export default PublicLayout;