import { useState } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import { withStyles } from '@material-ui/core/styles';
import { getColor } from '../../../../helpers/utils';
import Radio from '@mui/material/Radio';
import { styled } from '@mui/material/styles';
import Select from '@material-ui/core/Select';
import { FieldRenderProps } from 'react-final-form';
import css from './select-field.module.css';
import cx from 'classnames';

interface TextFieldProps extends FieldRenderProps<string, HTMLElement> {
  options: SelectOption[];
  label: string;
  disabled?: boolean;
  errorStyle?: string;
  testId?: string;
  multipleSelection?: boolean;
  form?: any;
}

export interface SelectOption {
  value: string;
  label: string;
  disable?: boolean;
}

const CssSelect = withStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    height: 50,
    fontSize: 13,
    fontFamily: 'Montserrat-Regular',
    border: '1px solid',
    backgroundColor: getColor('--color-white'),
    borderColor: getColor('--color-grey-border'),
    boxSizing: 'border-box',
    paddingLeft: '13px',
    fontWeight: 600,
    '&:focus': {
      backgroundColor: getColor('--color-white'),
    },
  },
}))(Select);

const CssFormControl = withStyles(() => ({
  root: {
    width: '100%',
    boxSizing: 'border-box',
    '& .MuiInput-underline::before': {
      display: 'none',
    },
    '& .MuiInput-underline:after': {
      display: 'none',
    },
  },
}))(FormControl);

const BpIcon = styled('span')(({ theme }) => ({
  borderRadius: '50%',
  width: 22,
  height: 22,
  marginRight: 20,
  boxShadow:
    theme.palette.mode === 'dark'
      ? '0 0 0 1px rgb(16 22 26 / 40%)'
      : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
  backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
  backgroundImage:
    theme.palette.mode === 'dark'
      ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
      : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  '.Mui-focusVisible &': {
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: 2,
  },
  'input:hover ~ &': {
    backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background:
      theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: '#9F2241',
  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&:before': {
    display: 'block',
    width: 22,
    height: 22,
    backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: '#9F2241',
  },
});

// Inspired by blueprintjs
function BpRadio(props: any) {
  return (
    <Radio
      sx={{
        '&:hover': {
          bgcolor: 'transparent',
        },
      }}
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      {...props}
    />
  );
}

const SelectField = (
  {
    label,
    options,
    input,
    meta: { touched, error, visited },
    disabled,
    errorStyle,
    testId,
    multipleSelection,
    form
  }: TextFieldProps,
) => {

  const [selectValues, setSelectValues] = useState<string[]>([]);
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    form.change(input.name, event.target.value);
    setSelectValues(event.target.value as string[]);
  };

  return (
    <CssFormControl className={css.formControl}>
      <CssSelect
        MenuProps={
          {
            getContentAnchorEl: null,
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left'
            },
          }
        }
        error={!!(touched && error)}
        labelId="select"
        id="select"
        displayEmpty={true}
        value={multipleSelection ? selectValues : input.value}
        renderValue={
          multipleSelection && selectValues.length
            ? (selected) => (selected as string[]).join(', ')
            : input.value.length
              ? undefined
              : () => <p className={css.labelText}>{label}</p>
        }
        disabled={disabled}
        multiple={multipleSelection}
        onChange={multipleSelection ? (e) => handleChange(e) : (e) => input.onChange(e)}
        test-id={testId}
      >
        {options.map((option: SelectOption) =>
          <MenuItem
            key={option.value}
            value={option.value}
            test-id={option.label}
            disabled={option.disable}
            className={css.menuItem}
          >
            <BpRadio
              value={option.value}
              label={option.value}
              checked={multipleSelection ? selectValues.includes(option.value) : option.value === input.value}
            />
            {option.label}
          </MenuItem>
        )}
      </CssSelect>
      <div className={cx(css.textFieldGroupError, errorStyle)}>
        {!!(touched && error) && (error)}
      </div>
    </CssFormControl >
  );
};

export default SelectField;