export const getColor = (color: string) => {
    return window.getComputedStyle(window.document.documentElement).getPropertyValue(color) || '#FFFFFF';
};

export const parserValues = (values: any) => {

    const telefonoCompleto = `${values.CodigoArea} - ${values.Telefono}`;

    const keys: any = Object.entries(values);
    let newObj: any = {}
    let finalObj: any = {}
    keys.forEach(([key, value]: any) => {
        if (key === 'Politicas') {
            newObj['Acepta Politicas'] = 'Si';
        }
        if (key === 'Beneficios') {
            newObj['Beneficios Opcionales'] = value.length > 0 ? value.join(', ') : 'No eligió';
        }

        return newObj[key] = value;
    });

    newObj = Object.entries(newObj).filter(([key]) =>
        !!(
            key !== 'Telefono' &&
            key !== 'CodigoArea' &&
            key !== 'Beneficios' &&
            key !== 'Politicas'
        )
    ).forEach(([key, value]) => {
        return finalObj[key] = value;
    })

    return {
        ...finalObj,
        Telefono: telefonoCompleto,
    };

};

const createImage = (url: string) =>
    new Promise((resolve, reject) => {
        const image = new Image();
        image.addEventListener('load', () => resolve(image));
        image.addEventListener('error', (error) => reject(error));
        image.setAttribute('crossOrigin', 'anonymous'); // needed to avoid cross-origin issues on CodeSandbox
        image.src = url;
    });


function getRadianAngle(degreeValue: number) {
    return (degreeValue * Math.PI) / 180;
}

export async function getCroppedImg(imageSrc: any, pixelCrop: any, rotation = 0) {
    const image: any = await createImage(imageSrc);
    const canvas = document.createElement('canvas');
    const ctx: any = canvas.getContext('2d');

    const maxSize = Math.max(image.width, image.height);
    const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

    // set each dimensions to double largest dimension to allow for a safe area for the
    // image to rotate in without being clipped by canvas context
    canvas.width = safeArea;
    canvas.height = safeArea;

    // translate canvas context to a central location on image to allow rotating around the center.
    ctx.translate(safeArea / 2, safeArea / 2);
    ctx.rotate(getRadianAngle(rotation));
    ctx.translate(-safeArea / 2, -safeArea / 2);

    // draw rotated image and store data.
    ctx.drawImage(
        image,
        safeArea / 2 - image.width * 0.5,
        safeArea / 2 - image.height * 0.5
    );
    const data = ctx.getImageData(0, 0, safeArea, safeArea);

    // set canvas width to final desired crop size - this will clear existing context
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;

    // paste generated rotate image with correct offsets for x,y crop values.
    ctx.putImageData(
        data,
        Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
        Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y)
    );

    // As Base64 string
    // return canvas.toDataURL('image/jpeg');

    // As a blob
    return new Promise((resolve) => {
        canvas.toBlob((file) => {
            resolve(file);
        }, 'image/png');
    });
}

export const readFile = (file: any) => {
    return new Promise((resolve) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => resolve(reader.result), false);
        reader.readAsDataURL(file);
    });
};
