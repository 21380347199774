import React, { useState, useMemo } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import { ReactComponent as LogoPrevincaD } from '../../../../assets/logoMenuD.svg';
import { ReactComponent as LogoPrevincaM } from '../../../../assets/logoMenuM.svg';
import { ReactComponent as LogoPrevincaMenu } from '../../../../assets/logoMenuPrimary.svg';
import { ReactComponent as MenuIcon } from '../../../../assets/menuIcon.svg';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import css from './navbar.module.css';
import PersonIcon from '@mui/icons-material/Person';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useHistory } from 'react-router-dom';
import { useMediaQuery } from '@material-ui/core';
import { Link, scroller } from 'react-scroll';

const SideBar: React.FC = () => {

  const isMobile = useMediaQuery('(max-width:860px)');
  const isDesktop = useMediaQuery('(min-width: 860px)');
  const history = useHistory();

  const [open, setOpen] = useState(false);

  const handleScroll = (name: string) => {
    setOpen(false);
    if (name === 'Tenemos_Un_Plan_Para_Vos') {
      scroller.scrollTo(name, {
        duration: 1000,
        delay: 0,
        smooth: 'easeInOutQuart'
      })
    }
    if (name === 'Nuestros_Planes') {
      scroller.scrollTo(name, {
        duration: 1000,
        delay: 0,
        offset: 70,
        smooth: 'easeInOutQuart'
      })
    }
  }

  const MenuItems = useMemo(() => (
    <Box
      sx={{ width: css.containerBox }}
      role="presentation"
    >
      <div className={css.headerItems}>
        <div className={css.containerMenuLogo}>
          <LogoPrevincaMenu />
        </div>
        <IconButton className={css.containerCloseIcon} onClick={() => setOpen(false)}>
          <CloseIcon />
        </IconButton>
      </div>
      <div className={css.listContainer}>
        <button className={css.itemText} onClick={() => handleScroll('Tenemos_Un_Plan_Para_Vos')}>
          Cotizá
        </button>
        <button className={css.itemText} onClick={() => handleScroll('Nuestros_Planes')}>
          Planes
        </button>
        <button className={css.itemText} onClick={() => window.location.replace('https://grupoprevincasl.com.ar/')}>
          Volver
        </button>
      </div>
    </Box>
  ), []);

  return (
    <>
      <div className={css.headerMenu}>
        {isMobile && (
          <div className={css.iconContainer}>
            <IconButton onClick={() => setOpen(true)}>
              <div className={css.imgContainer}>
                <MenuIcon />
              </div>
            </IconButton>
          </div>
        )}
        <div className={css.logoContainer}>
          <div className={css.imgContainer}>
            {isMobile ? <LogoPrevincaM /> : <LogoPrevincaD />}

          </div>
        </div>
        {isDesktop && (
          <div className={css.itemsMenuDesktop}>
            <Link activeClass="active" className={css.item} to="Tenemos_Un_Plan_Para_Vos" spy={true} smooth={true} duration={500}>
              <span className={css.textItem}>Cotizá</span>
            </Link>
            <Link activeClass="active" className={css.item} to="Nuestros_Planes" spy={true} smooth={true} duration={500} offset={75} >
              <span className={css.textItem}>Planes</span>
            </Link>
            <IconButton className={css.iconLogin} onClick={() => history.push('/admin')}>
              <PersonIcon />
            </IconButton>
            <IconButton className={css.iconBack} onClick={() => window.location.replace('https://grupoprevincasl.com.ar/')}>
              <ArrowBackIcon className={css.arrowIcon} />
            </IconButton>
          </div>
        )}
      </div>
      <Drawer
        anchor={'left'}
        open={open}
        onClose={() => setOpen(false)}
        className={css.containerDraw}
      >
        {MenuItems}
      </Drawer>
    </>
  )
};

export default SideBar;