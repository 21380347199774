import css from './admin.module.css';
import { useHistory } from 'react-router-dom';
import React, { useCallback, useContext } from 'react';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import MaterialButton from '@material-ui/core/Button';
import ListItem from '@material-ui/core/ListItem';
import ListItemTextMaterial from '@material-ui/core/ListItemText';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Input from '@material-ui/icons/Input';
import { withStyles } from '@material-ui/core/styles';
import { getColor } from '../../../helpers/utils';
import AuthContext from '../../../context/auth/authContext';

const ListItemText = withStyles(() => ({
  primary: {
    fontFamily: 'Montserrat-Regular',
  },
}))(ListItemTextMaterial);

const LogoutButton = withStyles(() => ({
  root: {
    fontFamily: 'Montserrat-SemiBold',
    color: getColor('--color-white'),
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'space-evenly',
    width: 200,
  },
}))(MaterialButton);

export interface Props {
  children: React.ReactNode;
}

const AdminLayout: React.FC<Props> = (props) => {
  const history = useHistory();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const authContext = useContext(AuthContext);
  const { logout } = authContext;

  const handleDrawerToggle = useCallback(() => {
    setMobileOpen(!mobileOpen);
  }, [setMobileOpen, mobileOpen]);

  const drawer = (
    <div className={css.sidebar} test-id="sideBar">
      <div className={css.mainTitle}>
        <label className={css.layoutNavbarTop} test-id="layoutNavbarTop">
          ADMIN
        </label>
        <label className={css.layoutNavbarTop} test-id="layoutNavbarTop">
          PREVINCA
        </label>
      </div>
      <List className={css.tabsContainer} test-id="tabsContainer">
        <ListItem
          key={'Planes'}
          button={true}
          selected={history.location.pathname === '/admin/planes'}
          onClick={() => history.push('/admin/planes')}
        >
          <ListItemText primary={'Planes'} test-id="listitemText" />
        </ListItem>
        <ListItem
          key={'Banner'}
          button={true}
          selected={history.location.pathname === '/admin/banner'}
          onClick={() => history.push('/admin/banner')}
        >
          <ListItemText primary={'Banner'} test-id="listitemText" />
        </ListItem>
      </List>
      <div className={css.layoutNavbarFooter} test-id="layoutNavBarFooter">
        <LogoutButton onClick={() => logout()}>
          Cerrar Sesión
          <Input />
        </LogoutButton>
      </div>
    </div >
  );

  return (
    <div className={css.container}>
      <div className={css.drawerContainer}>
        <CssBaseline />
        <Hidden only={['md', 'sm', 'lg', 'xl']} implementation="css">
          <AppBar position="fixed" className={css.appBar} test-id="appBar">
            <Toolbar className={css.toolbar} test-id="toolBar">
              <IconButton
                color="inherit"
                aria-label="closed drawer"
                edge="start"
                onClick={handleDrawerToggle}
                className={css.menuButton}
              >
                <MenuIcon />
                <Typography variant="h6" noWrap={true}>
                  ADMIN PREVINCA
                </Typography>
              </IconButton>
            </Toolbar>
          </AppBar>
        </Hidden>
        <nav className={css.drawer} aria-label="mailbox folders">
          <Hidden xsDown={true} implementation="css">
            <Drawer
              classes={{ paper: css.drawerPaper }}
              variant="permanent"
              open={true}
            >
              {drawer}
            </Drawer>
          </Hidden>
        </nav>
      </div>
      <div className={css.children}>
        <div className={css.root}>
          {props.children}
        </div>
      </div>
    </div>
  );
};

export default AdminLayout;