import React, { useRef, useState } from "react";
import TextField from "../text-field";
import SelectField from "../select-field";
import Checkbox from "../checkbox";
import Button from "@material-ui/core/Button";
import css from "./for-me-form.module.css";
import { Field, Form } from "react-final-form";
import { useHistory } from "react-router";
import {
  localidadesOptions,
  beneficiosOptions,
} from "../../../../helpers/data";
import { onlyNums } from "../../../../helpers/forms/normalize";
import {
  composeValidators,
  email,
  minLength,
  required,
} from "../../../../helpers/forms/validators";
import createDecorator from "final-form-focus";
import clienteAxios from "../../../../config/axios";
import CircularProgress from "@material-ui/core/CircularProgress";
import { parserValues } from "../../../../helpers/utils";
import cx from "classnames";
import ReCAPTCHA from "react-google-recaptcha";

const focusOnError = createDecorator();

interface CaptchaRef {
  getValue: () => string;
  // Otras propiedades del componente de captcha, si es que las hay
}

const ForMeForm: React.FC = () => {
  const history = useHistory();
  const captcha = useRef<CaptchaRef>(null);
  const [validCaptcha, setValidCaptcha] = useState(false);
  const onChange = () => {
    let token = captcha?.current?.getValue();
    if (token) {
      setValidCaptcha(true);
    } else {
      setValidCaptcha(false);
    }
  };

  return (
    <div className={css.containerForm}>
      <Form
        onSubmit={async (values: any) => {
          if (values) {
            const parsedValues = parserValues(values);
            const response = await clienteAxios.post(
              "/api/email/send",
              parsedValues
            );
            if (response && response.status === 200) {
              return history.push("/success");
            }
          }
        }}
        initialValues={{}}
        decorators={[focusOnError]}
        mutators={{
          setAreaCode: (args, state, utils) => {
            const value = onlyNums(args[0].target.value);
            if (
              args[0].target.value.length === 1 &&
              args[0].target.value === "0"
            ) {
              utils.changeValue(
                state,
                "CodigoArea",
                () => `${value.toString()}`
              );
            } else {
              utils.changeValue(
                state,
                "CodigoArea",
                () => `0${value.toString()}`
              );
            }
          },
          setPhoneNumber: (args, state, utils) => {
            const value = onlyNums(args[0].target.value);
            const prefix = value.toString().substr(0, 2);
            if (prefix === "15") {
              utils.changeValue(state, "Telefono", () => `${value.toString()}`);
            } else {
              utils.changeValue(
                state,
                "Telefono",
                () => `15${value.toString()}`
              );
            }
          },
        }}
      >
        {({ form, handleSubmit, values, submitting, pristine }) => (
          <form className={css.formContainer} onSubmit={handleSubmit}>
            <Field
              className={css.input}
              name="Nombre"
              validate={required}
              render={TextField}
              errorStyle={css.errorText}
              label="Ingresá tu nombre"
            />
            <Field
              className={css.input}
              name="Email"
              validate={composeValidators(required, email)}
              render={TextField}
              errorStyle={css.errorText}
              label="Ingresá tu email"
            />
            <div className={css.phoneInputs}>
              <Field
                className={css.inputAreaCode}
                name="CodigoArea"
                validate={composeValidators(required, minLength(3))}
                onChange={form.mutators.setAreaCode}
                render={TextField}
                isNumberField={true}
                labelNumber={"0"}
                errorStyle={css.errorText}
                label="Código de Area"
              />
              <Field
                className={css.inputNumber}
                name="Telefono"
                validate={composeValidators(required, minLength(6))}
                render={TextField}
                isNumberField={true}
                labelNumber={"15"}
                onChange={form.mutators.setPhoneNumber}
                errorStyle={css.errorText}
                label="Número telefonico"
              />
            </div>
            <div className={css.selectInput}>
              <Field
                className={css.input}
                name="Localidad"
                validate={required}
                render={SelectField}
                errorStyle={css.errorText}
                options={localidadesOptions}
                label="Seleccioná tu localidad"
              />
            </div>
            <div className={css.selectInput}>
              <Field
                className={css.input}
                name="Beneficios"
                render={SelectField}
                multipleSelection={true}
                errorStyle={css.errorText}
                options={beneficiosOptions}
                label="Beneficios opcionales"
                form={form}
              />
            </div>
            <div>
              <ReCAPTCHA
                ref={captcha as React.RefObject<ReCAPTCHA>}
                sitekey="6Lf19qglAAAAANF4JCD9XmpqseZ1dwZgYPcFafQJ"
                onChange={onChange}
              />
            </div>
            <div className={css.termsAndPoliciesContainer}>
              <button
                onClick={() => history.push("/terminos-y-politicas")}
                className={css.titleTermsAndPolicies}
              >
                Leer terminos y politicas de privacidad
              </button>
              <div className={css.containerCheckbox}>
                <Field
                  name="Politicas"
                  component={Checkbox}
                  validate={required}
                  labelStyle={css.labelStyle}
                  type="checkbox"
                  label={
                    "Estoy de acuerdo con los terminos y politicas de privacidad"
                  }
                  testId="terms-and-conditions-checkbox"
                />
              </div>
            </div>
            {validCaptcha && (
              <Button
                type="submit"
                variant="text"
                disabled={submitting}
                size="medium"
                className={cx(css.buttonForm, {
                  [css.disabled]: submitting,
                })}
                test-id="create-account-influencer-button"
              >
                {submitting ? <CircularProgress size={10} /> : "Cotizá ahora"}
              </Button>
            )}
          </form>
        )}
      </Form>
    </div>
  );
};

export default ForMeForm;
