import React, { useEffect } from 'react';
import css from './terms-and-conditions.module.css';
import { useHistory } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Button from '@material-ui/core/Button';

const TermsAndConditions: React.FC = () => {

  const history = useHistory();
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [])

  return (
    <>
      <div className={css.headerContainer}>
        <IconButton onClick={() => history.goBack()}>
          <div className={css.imgContainer}>
            <ArrowBackIcon style={{ fill: "white" }} />
          </div>
        </IconButton>
        <span className={css.headerTitle}>Terminos y políticas de privacidad</span>
      </div>
      <div className={css.termsAndConditionsContainer}>
        <div className={css.containerTextAndButton}>
          <span className={css.text}>
            Los datos que ingreses para cotizar un plan médico y/o para solicitar tu afiliación serán almacenados en la base de datos de Grupo Previnca San Lorenzo.
            (sita en Av. San Martín 2098, San Lorenzo, Santa Fe) y resguardados según sus políticas de seguridad. Existen campos optativos y obligatorios,
            en caso de omisión y/o inexactitud de alguno de los datos obligatorios, no podremos realizar la cotización solicitada.
            La cotización de un plan y/o la solicitud de afiliación importará autorizar a Grupo Previnca San Lorenzo. a utilizar los datos no sensibles en campañas de promoción
            de sus productos o servicios. Por último, te informamos que tenés la posibilidad de ejercer los derechos de acceso, rectificación y supresión de los
            datos (artículos 6, 14, 16, 17 y concordantes, Ley 25.326). LA AGENCIA DE ACCESO A LA INFORMACIÓN PÚBLICA, en su carácter de Órgano de
            Control de la Ley N° 25.326, tiene la atribución de atender las denuncias y reclamos que interpongan quienes resulten afectados en sus derechos por
            incumplimiento de las normas vigentes en materia de protección de datos personales. Una vez perfeccionado el contrato de acuerdo a lo estipulado
            precedentemente, el contratante podrá, en el término de DIEZ (10) días, revocar el presente conforme a lo dispuesto en el artículo 34 de la Ley Nº 24.240.
          </span>
          <div className={css.backButton}>
            <Button
              variant="text"
              size="medium"
              onClick={() => history.goBack()}
              className={css.button}
              test-id="create-account-influencer-button"
            >
              Volver
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default TermsAndConditions;